<template>
  <page-header :title="$t('paths.error')" />
</template>

<script>
export default {
  components: {
    "page-header": () => import("../components/PageHeader.vue"),
  },
};
</script>

